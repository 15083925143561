
.container {
    width: 100%;
}
.betel__gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;

}

.betel__gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 20px;
}

.betel__gallery .pics:hover {
    filter: opacity(.8);
}

.heading {
    text-transform: uppercase;
    font-size: 25pxrem;
    letter-spacing: 3px;
    margin-right: -3px;
    margin-bottom: 5rem;
    text-align: center;
    color: #fff;
    position: relative;
}

.heading:after {
    content: "";
    width: 10rem;
    height: .8rem;
    background-color: #45e19f;
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2rem;

}

@media screen and (max-width: 991px) {
    .betel__gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media screen and (max-width: 480px) {
    .betel__gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        padding: 0 12px;
    }
}

@media screen and (max-width:1050px) {


    .heading {
        font-size: 16px;
    }

    .heading:after {
        
        width: 7rem;
        height: .4rem;
        background-color: #45e19f;
    }
    
}

/* model*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal {
    background-color: #fff;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 20px;
    position: relative;
    text-align: center;
  }


  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }