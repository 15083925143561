.betel__servicii_inchinare {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 20rem 2rem 20rem;
}

.betel__containers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text);
    text-align: center;
    border-radius: 0.55em;
    margin: 0.5rem;
    cursor: pointer;
}

.betel__containers:hover  {
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 55%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 55%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 55%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 55%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 55%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: rgb(7, 69, 6);
    font-weight: bold;
}

.betel__containers img {
    width: 60px;
    height: 60px;
    margin: 1rem;
}

.betel__containers h1 {
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 30px;
    min-width: 100px;
    margin: 1rem;
    color: var(--color-subtext);
}

.betel__containers h4 {
    font-family: var(--font-family);
    font-size: 10px;
    min-width: 100px;
    margin: 0;
    min-width: 120px;
    color: #FFF;
}

.betel__containers p {
    font-family: var(--font-family);
    font-size: 12px;
    min-width: 80px;
    color: var(--color-text);
    margin: 2rem;
    text-align: justify;
}

@media screen and (max-width: 1800px) {
    .betel__servicii_inchinare {
        margin: 20px 20px 0px 20px;
    }
}

@media screen and (max-width: 1050px) {
    .betel__containers{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        text-align: center;
    }

    .betel__containers img {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}