.betel__despre_noi {
    display: flex;
    flex-direction: row;
    margin: 0rem 25rem 2rem 25rem;
    padding: 2rem;
    background: var(--color-footer);
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    scroll-margin-top: 100px;
}

.betel__despre_noi-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.betel__despre_noi-info h1 {
    font-size: 25px;
    font-family: var(--font-family);
    color: var(--color-subtext);
    text-align: center;
    
}

.betel__despre_noi-welcome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.betel__despre_noi-welcome p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text);
    cursor: pointer;
    margin: 4rem 6rem;
    text-align: center;
}

@media screen and (max-width: 1800px) {
    .betel__despre_noi {
        margin: 20px;
        background-color: red;
    }
}

@media screen and (max-width: 850px) {
    .betel__despre_noi {
        flex-direction: column;
        align-items: flex-start;
        border: 0;
    }

    .betel__despre_noi-welcome p {
        margin: 1rem 0;
    }
    .betel__despre_noi-info h1 {
        font-size: 22px;
    }
}