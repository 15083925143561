

.betel__navbar-menu {
    display: flex;
    align-items: flex-end;
}

.custom-navbar-bg {
    background-color: rgb(68, 97, 126);
  }

.custom-nav-links .nav-link {
    margin-right: 15px; 
    color: white;
    font-family: var(--font-family);
}

.navbar-brand-text {
    margin-left: 30px; 
    color: white;
}


@media screen and(max-width:998px) {
    .betel__navbar-logo {
        width: 25px;
        height: 20px;
    }
}

