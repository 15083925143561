.betel__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 25rem 2rem 25rem;
    margin-top: 7rem;
    padding: 3rem 1rem;
    background: var(--color-footer);
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    scroll-margin-top: 100px;
}

.betel__contact-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-right: 4rem;
    margin: 3rem;
}

.betel__contact-info h1 {
    font-family: var(--font-family);
    text-align: left;
    color: #fff;
    font-size: 15px;
    line-height: 70px;
}

.betel__contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
}

.betel__contact-form h2 {
    font-family: var(--font-family);
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.betel__contact-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.betel__contact-numeemail{
    display: flex;
    justify-content: center;
    align-items: center;

}

.betel__contact-numeemail .text-box {
    background: #040C18;
    color: #fff;
    border: none;
    width: calc(60%);
    height: 40px;
    padding: 10px;
    font-size: 15px;
    border-radius: 1rem;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    margin-right: 1rem;
    opacity: 0.9;
}

.betel__contact-form textarea {
    background: #040C18;
    width: 99%;
    color: #fff;
    border: none;
    min-height: 200px;
    max-height: 400px;
    padding: 12px;
    font-size: 15px;
    resize: vertical;
    border-radius: 1rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-right: 1rem;
    opacity: 0.9;
}

.betel__contact-form .send-btn {
    font-family: var(--font-family);
    float: right;
    background: #2E94E3;
    color: #fff;
    border: none;
    width:120px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 1rem;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
@media screen and (max-width: 1800px) {
    .betel__contact {
        margin: 2rem;
    }
    .betel__contact-form textarea {
        width: 98%;
    }
}

@media screen and (max-width: 950px) {
    .betel__contact {
        flex-direction: column;
        margin: 3rem;
    }

    .betel__contact-info h1 {
        font-size: 15px;
    }

    .betel__contact-form {
        width: 100%;
    }

    .betel__contact-form h2 {
        font-size: 25px;
    }
    .betel__contact-numeemail {
        border: 0;
    }
    .betel__contact-form textarea {
        width: 98%;
    }
    
}

@media screen and (max-width: 550px) {
    .betel__contact {
        width: 75%;
        margin-left: 3rem;
    }
    .betel__contact-numeemail {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .betel__contact-numeemail .text-box {
        width: 95%;
    }
    .betel__contact-form textarea {
        width: 95%;
    }
}
